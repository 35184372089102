@import 'src/styles/settings/settings';

.headerWrap {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  padding: 32px 10px;
  font-weight: 500;
  @include media('>=tablet') {
    flex-direction: row;
    padding: 32px 30px;
  }

  &_logo {
    margin-bottom: 15px;
    cursor: pointer;
    color: var(--text-color);
    @include media('>=tablet') {
      margin: 0;
    }
    &Accent {
      font-weight: 400;
      color: var(--accent-color);
    }
  }

  &_middleBlock {
    width: 100%;
    @include media('>=tablet') {
      width: auto;
      margin-left: auto;
    }
  }

  &_rightBlock {
    margin-left: 2rem;
    &_userMinutes {
      display: flex;
      flex-direction: column;
      text-align: right;
      cursor: pointer;
      transition: all 1.5s ease;
      &:hover {
        .headerWrap_rightBlock_userMinutes_text,
        .headerWrap_rightBlock_userMinutes_countdown {
          color: var(--secondary-color);
        }
      }
      &_text {
        font-size: 16px;
        font-weight: 400;
        color: var(--description-color);
      }
      &_countdown {
        span {
          font-weight: 600;
        }
        font-size: 16px;
        font-weight: 500;
        color: var(--minutes-color);
      }
    }
  }
}

.searchInputWrapper {
  position: relative;
  margin-bottom: 10px;
  @include media('>=tablet') {
    margin: 0;
  }

  &_prtfix {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 45px;
    top: 0;
    bottom: 0;
    left: 0;

    img {
      width: 20px;
      height: 20px;
    }
  }

  &_input {
    background: var(--search-input-bg);
    border: 1px solid var(--search-input-border);
    border-radius: 52px;
    padding: 12px 8px 12px 42px;
    color: var(--search-input-color);
    font-size: 16px;
    width: 100%;
    @include media('>=laptop') {
      min-width: 300px;
    }
    &::placeholder {
      color: color(secondary);
    }

    &:hover {
      border-color: transparent;
      outline: none;
    }
    &:focus {
      outline: none;
      border: 1px solid var(--input-color);
      box-shadow: 0 0 6px 0 var(--grey-2);
    }
  }
}
