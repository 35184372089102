@import 'src/styles/settings/settings';

.inputWrap {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;

  &_label {
    margin-bottom: 24px;
  }

  &_field {
    width: 100%;
    position: relative;

    &_prefix {
      width: 42px;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &_postfix {
      width: 42px;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.input {
  width: 100%;
  border-radius: 52px;
  border: 1px solid var(--input-border);
  background: var(--input-bg);
  box-shadow: var(--input-shadow);
  outline: none;
  transition: all 0.2s ease-in-out;
  padding: 14px 12px;
  color: var(--input-color);
  height: 50px;
  font-size: 14px;

  &:focus {
    border-color: transparent;
  }

  &.error {
    border-color: color(redMain);
  }

  &.prefix {
    padding-left: 42px;
  }

  &.postfix {
    padding-right: 42px;
  }

  &::placeholder {
    color: color(secondary);
  }
}

.errorText {
  color: color(redMain);
  font-size: 16px;
  position: absolute;
  bottom: -20px;
}

input:-webkit-autofill {
  -webkit-text-fill-color: var(--input-color) !important;
  border: 1px solid var(--input-border);
  background: var(--input-bg);
  font-size: 21px !important;
  box-shadow: var(--input-shadow) !important;
  &:hover, &:focus, &:active {
    border-color: color(greenBorder) !important;
    box-shadow: var(--input-shadow) !important;
    font-size: 21px !important;
  }
}

/* Additional styles to ensure consistency */
input:-webkit-autofill::first-line {
  color: var(--input-color) !important;
  font-size: 21px !important;
}

input:-webkit-autofill:focus,
input:-webkit-autofill:hover {
  font-size: 21px !important;
  background: var(--input-bg);
  -webkit-text-fill-color: var(--input-color) !important;
}
