@import 'src/styles/settings/settings';

$font-size: 14px;

.btn-wrapper {
  position: relative;
  width: 100%;
  cursor: pointer;
  font-weight: 500;
  padding: 0;

  &.disabled {
    cursor: not-allowed;
  }

  .btn {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    width: 100%;
    height: 40px;
    padding: 0;
    box-sizing: content-box;
    border: 1px solid var(--primary-light-1);
    background: none;
    transition: all 0.2s ease;

    &_primary {
      background: linear-gradient(#7ed4a5 10%, #62d998 100%);
      color: color(white);

      &:hover {
        background: linear-gradient(
          180deg,
          #50c786 20%,
          #62d998 60%,
          #b3ffe5 100%
        );
        color: darken(color(white), 10%);
      }

      &-middle {
        width: 100%;
        height: 100%;
        border-radius: 12px;
        background: linear-gradient(180deg, #7ed4a5 10%, #62d998 100%);
        transition: all 0.2s ease;

        &:hover {
          background: linear-gradient(
            180deg,
            #50c786 20%,
            #62d998 60%,
            #b3ffe5 100%
          );
        }
      }

      &-wrap {
        padding: 12px;
        max-height: 100%;
        width: 100%;
        border-radius: 12px;
        transition: all 0.2s ease;

        &:hover {
          background: linear-gradient(
            180deg,
            #50c786 20%,
            #62d998 60%,
            #b3ffe5 100%
          );
        }
      }
    }

    &_primaryDark {
      color: color(secondaryDark);
      background: linear-gradient(#7ed4a5 10%, #62d998 100%);

      &:hover {
        background: linear-gradient(
          180deg,
          #50c786 20%,
          #62d998 60%,
          #b3ffe5 100%
        );
      }
    }
  }

  .loader {
    :global .icon {
      @include icon-size(calc(#{$font-size + 8px}));
      color: color(white);
      padding: 0;
    }
  }

  .content {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    padding: 20px;
    font-size: 18px;
    /* stylelint-disable custom/color-no-hex */
    border: #043d21;
    border-width: 10px;
    color: #054626;
    /* stylelint-enable custom/color-no-hex */
    img {
      margin-right: 0px;
    }
    &.loading {
      visibility: hidden;
    }
  }

  // New styles for collapsed state
  &.collapsed {
    .btn_primary,
    .btn_primary-middle,
    .btn_primary-wrap,
    .content {
      background: none !important;
      border: none !important;
      box-shadow: none !important;
      padding: 0 !important;
    }

    .content {
      color: inherit;
    }
  }
}

.button-container {
  padding-top: 20px;
}
