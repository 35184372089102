@import 'src/styles/settings/settings';

.noMobile {
  margin-top: 150px;
  width: 100%;
  &_container {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 90%;
    margin: auto;
    .noMobile_mainImg {
      display: none; // Hide the main image
      width: 50%;
      margin-bottom: 20px;
    }
    button {
      cursor: pointer;
      margin-top: 10px;
      cursor: pointer;
      padding: 15px 23px;
      img {
        width: 30px;
        margin-right: 10px !important;
      }
      p {
        font-size: 20px;
      }
    }
  }
  &_text {
    color: white;
    text-align: center;
    h1 {
      display: none; // Hide the h1 element
      font-size: 28px;
      margin-bottom: 10px;
    }
    p {
      font-size: 1rem !important;
    }
  }
}
