@import 'src/styles/settings/settings';

.buttonWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 4px 4px;
  border-radius: 8px;
  border: 1px solid transparent;
  min-height: 40px;
  min-width: 160px;
  transition: background 0.2s ease, box-shadow 0.2s ease, border 0.2s ease;

  &:hover {
    border: 1px solid var(--menu-border);
    background: var(--menu-hover-bg);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }

  &.active {
    border: 1px solid var(--menu-border);
    background: var(--menu-active-bg);
    box-shadow: 0 2px 10px rgba(54, 57, 53, 0.15);

    & div {
      color: var(--menu-active-color-2);
    }
    
  }

  &.disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }

  &_content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    font-size: 16px;
    font-weight: 600;
    color: var(--menu-color);

    &_img {
      margin-left: 16px;
      margin-right: 16px;
      width: 24px;
      height: 24px;

      &.pulseEffect {
        animation: pulse 2s infinite;
      }
    }
  }

  &.collapsed { // New class for collapsed state
    min-width: 52px; // Adjust the width for the collapsed sidebar

    .buttonWrapper_content {
      justify-content: center; // Center the icon in the button

      &_img {
        margin-left: 0px;
        margin-right: 0px;
      }
    }
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.1);
    opacity: 0.7;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
