// FONT
.p2 {
  font-size: 1.7rem;
}
.p4 {
  font-size: 1.2rem;
}

.box-shadow-1 {
  box-shadow: 0.3rem 0.3rem 0.5rem color(black, 0.2);
}
.box-shadow-2 {
  box-shadow: 0.4rem 0.4rem 0.4rem rgba(0, 0, 0, 0.25);
}
