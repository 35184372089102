@import 'src/styles/settings/settings';

.homePageWrapper {
  min-height: 100vh;
  width: 100%;
  display: flex;
  background: var(--content-background);

  &_sidebar {
    flex-shrink: 0;
    width: auto;
    display: flex;
  }
}

.contentContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.mainContent {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.contentContainer_content {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 15px 32px 15px;
  flex-grow: 1;

  @include media('>=tablet') {
    padding: 0 30px 32px 30px;
  }
}

:global(.footer) {
  flex-shrink: 0;
}