@import 'src/styles/settings/settings';

.logoMain {
  display: flex;
  justify-content: center;
  img {
    margin-top: 8px;
    width: 400px;
  }
}

.loginContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  // justify-content: center;
  justify-content: flex-start;
  padding: 0;
  max-height: 90vh;
  overflow: hidden;

  .modalContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    overflow: hidden;

    .imageContainer {
      display: flex;
      // justify-content: space-between; // Adjust spacing as needed
      // margin-bottom: 24px; // Ensure it matches the spacing of other elements
      width: 100%; // Adjust the width to fit the modal layout
      max-width: 100%; // Ensure it fits the modal layout
      height: 200px; // Adjust the height of the image container
      border-radius: 48px;
      box-shadow: 0 2px 10px 0 rgba(54, 57, 53, 0.15);
      overflow: hidden;
      
      img {
        width: 30%; // Adjust the width of each image
        height: 100%;
        object-fit: cover; // Maintain aspect ratio
        object-position: center; // Center the image
        margin-bottom: 0; // Ensure no extra margin is added
      }
    }

    .modalContent {
      padding: 24px;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      overflow-y: auto;
      margin-top: 24px;
    }

    &_subTitle {
      color: var(--description-color);
      font-size: 30px;
      font-weight: 500;
      text-align: center;
      margin-bottom: 48px;
    }
    &_loginButton {
      width: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      gap: 16px;
      max-width: 300px;
      &_contentWrap {
        padding: 15px 23px;
        width: 100%;
        &_content {
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 22.5px;
            margin-right: 10px;
          }
          .text {
            flex-grow: 1;
            text-align: center;
          }
        }
      }
    }
  }
}
