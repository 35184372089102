@import 'colors';

@mixin white-shadow($opacity: 0.1) {
  box-shadow: 0 0 5px 2px color(white, $opacity);
}

@mixin transition($property, $duration: 0.3s, $func: ease-in-out) {
  transition-property: $property;
  transition-duration: $duration;
  transition-timing-function: $func;
}

@mixin ellipsis-text {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

@mixin visible {
  @include transition(opacity);
  opacity: 1;
}

@mixin hidden {
  opacity: 0;
}

@mixin reset-button {
  border: none;
  outline: none;
  background-color: transparent;
  margin: 0;
  padding: 0;
  cursor: pointer;
  font-family: system-ui;
}

@mixin icon-size($width, $height: $width) {
  &,
  path {
    min-width: $width;
    max-width: $width;
    min-height: $height;
    max-height: $height;

    width: $width;
    height: $height;
  }
}

@mixin image-container($width, $height) {
  position: relative;
  height: $height;
  width: $width;
  overflow: hidden;

  img {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    object-fit: cover;
    min-height: 100%;
    max-height: 100%;
    min-width: 100%;
    max-width: 100%;
  }
}
