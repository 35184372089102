@import 'src/styles/main';

.windows {
  margin-right: 10px;
}

.install_macButtons {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Rubik', 'Fredoka', 'Inter', sans-serif;
  color: var(--light-2);
}
