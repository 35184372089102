.label-input-wrapper {
  display: flex;
  flex-direction: column;

  label {
    margin-bottom: 6px;
  }
}

// Blocks
.blockWrapper1 {
  background-color: color(main);
  border-radius: 1rem;
  padding: 2rem;
}
