@import 'src/styles/settings/settings';

.image-container {
  width: 200px;
  height: 250px;
}

.image-board-container {
  width: 550px;
  height: 250px;
}
.image-container-default {
  width: 100%;
  height: 100%;
}
