@import 'src/styles/settings/settings';

.footerWrap {
  width: 100%;
  font-weight: 500;
  background: var(--background-color);
  color: var(--text-color);
  z-index: 100;
  font-size: 0.7rem;

  &_main {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 25px 30px;
    
    @include media(">=tablet") {
      flex-direction: row;
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      height: 1px;
      background: var(--header-footer-border);
    }

    &_links {
      display: flex;
      align-items: center;
      justify-content: center;
      
      @include media("<tablet") {
        flex-direction: column;
        margin-top: 20px;
      }
    }
  }

  .link {
    cursor: pointer;
    color: inherit;
    text-decoration: none;
    margin: 0 9px;
    
    &:hover {
      color: inherit;
      text-decoration: none;
    }
    
    @include media("<tablet") {
      margin: 5px 0;
    }
    
    &:first-child {
      margin-left: 0;
    }
    
    &:last-child {
      margin-right: 0;
    }
  }
}