@import './settings/settings.scss';
@import './global';
@import './anims';
@import './settings/colors';
@import './fonts.scss';
@import 'react-toastify/dist/ReactToastify.css';
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fredoka:wght@300..700&family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');

// RESET
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

// FONT
h1 {
  font-size: 6rem;
}
h2 {
  font-size: 3.4rem;
}
h3 {
  font-size: 2.6rem;
  font-weight: 700;
}
h4 {
  font-size: 2.2rem;
  font-weight: 700;
}
p {
  font-size: 2rem;
}

// BASE
html {
  font-size: 21px;
}

body {
  background-color: color(bg);
  margin: 0;
  padding: 0;
  color: color(main);
  font-family: 'Rubik', 'Fredoka', 'Inter', sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

svg,
path {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-background-clip: text;
  -webkit-text-fill-color: #ffffff;
  transition: background-color 5000s ease-in-out 0s;
  box-shadow: inset 0 0 10px 10px #23232329;
}

code {
  font-family: 'Rubik', 'Fredoka', 'Inter', sans-serif;
}

button {
  font-family: 'Rubik', 'Fredoka', 'Inter', sans-serif;
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 0;
  appearance: none;
}
