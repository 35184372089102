@import 'src/styles/settings/settings';

.avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 0.5px solid var(--border-color-2);
  position: relative;
  &_image {
    border-radius: 50%;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &_full {
    min-width: 56px;
    width: 56px;
    height: 56px;
  }
  &_small {
    min-width: 48px;
    width: 48px;
    height: 48px;
  }
  &_mini {
    min-width: 33px;
    width: 33px;
    height: 33px;
  }

  .avatarFallback {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 53px;
    height: 53px;
    background-color: color(pastel7);
    color: var(--primary-light-1);
    font-size: 21px;
    font-weight: bold;
    border-radius: 50%;
    border: 1px solid var(--primary-light-1);
  }

  &_round {
    border-radius: 50%;
    position: absolute;
    width: 10px;
    height: 10px;
    background: color(yellowBorder);
    top: 0;
    right: 3px;
  }

  .status {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    border: 1px solid var(--border-color-2);

    &_online {
      background-color: color(statusOnline);
    }
  }
}
