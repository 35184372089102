@import 'src/styles/settings/settings';

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--modal-overlay-bg);
  backdrop-filter: blur(2px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}
.contentTitle {
  color: var(--modal-title);
  font-size: 21px;
  font-weight: 500;
  margin-bottom: 20px;
}
.modalContent {
  border-radius: 48px;
  border: 2px solid var(--modal-border);
  background: var(--modal-bg);
  box-shadow: 0 2px 10px 0 rgba(54, 57, 53, 0.15);
  padding: 20px;
  max-width: 680px;
  max-height: 100vh;
  width: 100%;
  margin: 0 auto;
  position: relative;

  .contentWrapper {
    max-height: 95vh;
    overflow-y: auto;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
}
