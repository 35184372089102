@import 'src/styles/settings/settings';

.modalChild {
  width: 100%;
  padding: 32px;
  .modalContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    &_logo {
      margin-bottom: 24px;
    }
    &_title {
      color: var(--title-color);
      font-size: 36px;
      font-weight: 700;
      margin-bottom: 24px;
    }
    &_subTitle {
      color: var(--description-color);
      font-size: 21px;
      font-weight: 500;
      text-align: center;
      margin-bottom: 48px;
    }
    &_form {
      width: 100%;
      &_field {
        width: 100%;
        margin-bottom: 24px;
        &_input {
          text-align: center;
          width: 550px;
          height: 63px;
          padding: 4px 8px;
          align-items: center;
          border-radius: 52px;
          border: 1px solid var(--input-border);
          background: var(--input-bg);
          box-shadow: var(--input-shadow);
          color: var(--input-color);
          font-size: 24px;
          font-weight: 500;
        }
      }

      &_action {
        width: 100%;
        display: flex;
        justify-content: center;
      }
    }
  }
}
