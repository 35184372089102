@import 'src/styles/settings/settings';

.sidebarWrapper {
  border-right: 1px solid var(--header-footer-shadow);
  background: var(--background-color);
  color: var(--text-color);
  box-shadow: 2px 0 8px rgba(0, 0, 0, 0.05);
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 280px;
  width: 100%;
  position: sticky;
  top: 0;
  height: 100vh;
  transition: max-width 0.3s ease;

  &.sidebarWrapperMin {
    max-width: 120px;
    padding: 8px;
  }

  &_logoWrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    width: 100%;

    img {
      cursor: pointer;
    }

    &_logo {
      transition: transform 0.2s ease-in-out;

      &:hover {
        transform: scale(1.15);
      }
    }

    &_toggle {
      border-radius: 6px;
      border: 1px solid transparent;
      padding: 4px;
      transition:
        background 0.3s ease,
        border 0.3s ease;

      &:hover {
        border: 1px solid var(--menu-border);
        background: var(--menu-active-bg);
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      }
    }

    &_center {
      justify-content: center;
    }
  }

  &_menu {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 16px;
    margin-top: 50px;

    &_item {
      width: 180px;
      margin-bottom: 6px;
      padding-top: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      border-radius: 6px;
      transition:
        background 0.3s ease,
        color 0.3s ease;

      .sidebarWrapperMin & {
        width: 50px;
        padding: 8px 0;
        text-align: center;
      }

      &:hover {
        background: var(--menu-hover-bg);
        color: var(--menu-active-color);
      }

      &.collapsed {
        .linkText {
          display: none;
        }
      }
    }
  }

  &_create {
    color: rgb(0, 0, 0);
    border: none;
    border-radius: 8px;
    padding: 8px 9px;
    font-size: 12px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 40px;
    max-height: 40px;
    width: 200px;
    padding-left: 10px;
    text-align: center;
    transition: all 0.3s ease;
    margin: 0 auto;
    background-color: var(
      --primary-color
    ); // Use a CSS variable for the background color

    .sidebarWrapperMin & {
      width: 40px;
      height: 40px;
      padding: 0;
      background-color: transparent !important; // Make background transparent when collapsed
      border: none !important;
      box-shadow: none !important;
    }

    &:hover,
    &:active,
    &:focus {
      transform: none !important;
      outline: none;
      box-shadow: none !important;
    }

    &_icon {
      width: 24px;
      height: 24px;
      margin-left: 8px;

      .sidebarWrapperMin & {
        margin-left: 0;
      }
    }

    &_text {
      .sidebarWrapperMin & {
        display: none;
      }
    }
  }
}

.joinProButton {
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 25px;
  background: var(--popup-bg-color);
  color: var(--text-color-secondary);
  border: 1px solid var(--border-color-2); // Added thick white border
  border-radius: 25px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  margin: 12px auto;
  position: relative;
  overflow: visible;
  transition: all 0.3s ease; // Added transition for smooth hover effect

  .buttonLogo {
    width: 15px;
    height: auto;
    margin-right: 8px;
  }

  span {
    position: relative;
    z-index: 1;
  }

  &:hover {
    //background: darken(var(--spot-color-1), 10%); // Darken the background on hover
    transform: translateY(-2px); // Slight lift effect on hover
  }

  .sidebarWrapperMin & {
    width: 40px;
    height: 40px;
    padding: 0;

    .buttonLogo {
      width: 20px; // Keeping the original size for the minimized sidebar
      margin-right: 0;
    }

    span {
      display: none;
    }
  }
}

/* Ensure the button wrapper is centered */
.btn-wrapper--BOBC8 {
  display: flex;
  justify-content: center;
  width: 100%;
}

/* Reset any conflicting styles */
.btn--Os6VR,
.btn_primary--Jk2bo,
.btn_primary-middle--NL_GR,
.btn_primary-wrap--fqzvc {
  background: none !important;
  border: none !important;
  box-shadow: none !important;
  padding: 0 !important;
  margin: 0 !important;
  width: auto !important;
}

.userDataWrap {
  position: relative;

  &_userData {
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    cursor: pointer;
    border-radius: 56px;
    pointer-events: auto;
    transition:
      background 0.3s ease,
      box-shadow 0.3s ease,
      border 0.3s ease;
    border: 1px solid transparent;

    &:hover {
      border-color: var(--menu-border);
      background: var(--menu-hover-bg);
      box-shadow: 0 2px 10px rgba(54, 57, 53, 0.1);
    }

    &_info {
      display: flex;
      flex-direction: column;
      width: 100%;
      overflow: hidden;

      &_name {
        color: var(--menu-color);
        font-size: 12px;
        font-weight: 500;
        margin-bottom: 4px;
        margin-left: 12px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      &_email {
        color: var(--menu-user-email);
        font-size: 12px;
        font-weight: 400;
        margin-left: 12px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }
}

.tooltip {
  visibility: hidden;
  position: absolute;
  bottom: 115%;
  left: 50%;
  border-radius: 6px;
  border: 1px solid var(--menu-border);
  background: var(--menu-active-bg);
  box-shadow: 0 1px 8px rgba(54, 57, 53, 0.1);
  transform: translateX(-50%);
  color: var(--menu-color);
  font-size: 14px;
  padding: 4px 8px;
  z-index: 10;
  opacity: 0;
  pointer-events: auto;
  transition:
    opacity 0.2s ease,
    visibility 0.2s ease;

  &::after {
    content: '';
    position: absolute;
    left: 50%;
    bottom: -5px;
    transform: translateX(-50%) rotate(45deg);
    width: 8px;
    height: 8px;
    background: var(--menu-active-bg);
    border-right: 1px solid var(--menu-border);
    border-bottom: 1px solid var(--menu-border);
  }

  &_sidebarClose {
    left: 80%;
    &::after {
      left: 30%;
    }
  }

  &_item {
    display: flex;
    align-items: center;
    font-weight: 400;
    color: var(--menu-color);

    &:not(:last-child) {
      margin-bottom: 6px;
    }

    &:hover {
      color: var(--menu-active-color);
    }

    img {
      width: 28px;
      height: 28px;
      padding: 4px;
    }
  }
}

.userDataWrap:hover .tooltip,
.tooltip:hover {
  visibility: visible;
  opacity: 1;
}

.downloadBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.4rem;
  margin: auto;
  margin-bottom: 1rem;
  transition: all 0.15s ease;
  padding-top: 20px;
  svg,
  p {
    color: var(--spot-color-1);
  }
  svg {
    font-size: 18px;
  }
  p {
    font-size: 14px;
  }
  &:hover {
    svg,
    p {
      color: var(--hover-color-1);
    }
  }
}
